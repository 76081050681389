@import 'src/utils/utils';

.aside-review {
  position: sticky;
  z-index: 1;
  bottom: 20px;
  left: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
  }
  @media(min-width: $lg) {
    bottom: 50px;
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    width: 100%;
    max-width: 1000px;
  }
  @media(min-width: 1921px) {
    margin-left: auto;
    margin-right: auto;
  }

  &__wrapper {
    @include Shadow-1;
    display: flex;
    padding: 10px;
    border-radius: 15px;
    background-color: $Main-2;
    gap: 15px;
    pointer-events: auto;
  }

  &__form {
    display: flex;
    gap: 10px;
  }

  &__label {
    cursor: pointer;

    &--red {

      input {
  
        &:checked {
    
          & + .aside-review__box {
            color: $Corporate-2;
            border-color: currentColor;
          }
        }
      }

      &:not(:disabled):hover {
        @media(min-width: $lg) {

          .aside-review__box {
            color: $Corporate-2;
          }
        }
      }
    }

    &--green {

      input {
  
        &:checked {
    
          & + .aside-review__box {
            color: $Alternative-4;
            border-color: currentColor;
          }
        }
      }

      &:not(:disabled):hover {
        @media(min-width: $lg) {

          .aside-review__box {
            color: $Alternative-4;
          }
        }
      }
    }
  }

  &__box {
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    color: $Main-1;
    border: 1px solid $Main-5;
    border-radius: 10px;
    transition:
      color 0.3s,
      border-color 0.3s;

    svg {
      width: 24px;
      height: 24px;
      stroke: currentColor;
    }
  }

  &__button {
    @include Text-14-reg;
    display: inline-grid;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 8px;
    padding-left: 20px;
    border: 0;
    border-radius: 10px;
    text-decoration: none;
    color: $Main-1;
    background-color: transparent;
    outline: 1px solid $Main-5;
    outline-offset: -1px;
    transition: color 0.3s;
    grid-auto-flow: column;
    column-gap: 10px;
    flex-shrink: 0;
    user-select: none;
    cursor: pointer;

    &:not(:disabled):hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      fill: none;
    }
  }
}